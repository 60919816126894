import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./headerProductCard.css";
import { Rate } from 'antd';
import QuickViewModal from './QuickViewModal';
import * as newApi from "../../api/apiCollection"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// icons imports

import { StarFilled, StarOutlined } from '@ant-design/icons';

// Reducer imports
import { addGuestCartTotal, addtoGuestCart, setCart, setCartProducts, setCartSubTotal, subGuestCartTotal } from '../../model/reducer/cartReducer';
import { useTranslation } from 'react-i18next';
import ProductVariantModal from './ProductVariantModal';
import ImageWithPlaceholder from '../image-with-placeholder/ImageWithPlaceholder';
import { BiHeart, BiSolidHeart } from 'react-icons/bi';
import { LuEye } from "react-icons/lu";
import { addFavoriteProductId } from '../../model/reducer/favouriteReducer';

const HeaderProductCard = ({ product, setIsShowCatDrop, setMenuData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { t } = useTranslation();
    // reducer imports
    const setting = useSelector(state => (state.setting));
    const cart = useSelector(state => (state.cart))
    const user = useSelector(state => (state.user))
    const favoriteProducts = useSelector(state => (state.favourite))

    // state variables
    const [p_id, setP_id] = useState(0);
    const [p_v_id, setP_V_id] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setselectedProduct] = useState({});
    const [selectedVariant, setSelectedVariant] = useState(product?.variants[0])
    const [showVariantModal, setShowVariantModal] = useState(false)

    const handleProductPageClick = () => {
        setIsShowCatDrop(false)
        setMenuData(false)
        navigate(`/product/${product.slug}`)
    }

    return (
        <div >
            <div className="product-grid" >
                <div >
                    <div className="product-image">
                        <a onClick={() => handleProductPageClick()} className="image">
                            <ImageWithPlaceholder src={product?.image_url} alt={product?.slug} />
                        </a>
                    </div>
                    <div className="header-product-content" onClick={() => navigate(`/product/${product.slug}`)}>
                        <div>
                            <h3 className="title"><a > {product?.name} </a></h3>
                            {product?.average_rating > 0 ?
                                <div className="rating">
                                    <Rate
                                        disabled
                                        defaultValue={2.6}
                                        allowHalf={true}
                                        style={{ fontSize: 15 }}
                                        characterRender={(node, { index }) => (
                                            <span className={index + 1 <= product?.average_rating ? "filledStar" : "emptyStar"}>
                                                {index + 1 <= product?.average_rating ? <StarFilled /> : <StarOutlined />}
                                            </span>
                                        )}
                                    />
                                    <p>{`(${product?.average_rating.toFixed(2)})`}</p>
                                </div>
                                : null}

                        </div>

                        {/* <div className="price">{setting.setting.currency}{selectedVariant?.
                            discounted_price !== 0 ? selectedVariant?.
                            discounted_price : selectedVariant?.
                            price}<span>{selectedVariant?.
                                discounted_price !== 0 && <>
                                    {setting.setting.currency}
                                    {selectedVariant?.
                                        price}
                                </>}</span>
                        </div> */}
                    </div>
                </div>

                {/* {
                    product?.variants?.length <= 1 && product?.variants?.[0]?.is_unlimited_stock == 0 && product?.variants?.[0]?.stock == 0 ? <span className='variant-out-of-stock'>{t("OutOfStock")} </span> :
                        <div className='product-btn'>
                            <button className='product-qty-btn' onClick={() => handleVariantModal(product)}>
                                {`${selectedVariant?.measurement} ${selectedVariant?.stock_unit_name}`} {product?.variants?.length > 1 ? <IoMdArrowDropdown /> : null}
                            </button>

                            {cart?.isGuest === false && cart?.cartProducts?.find((prdct) => prdct?.product_variant_id == selectedVariant?.id)?.qty > 0 ||
                                cart?.isGuest === true && cart?.guestCart?.find((prdct) => prdct?.product_variant_id === selectedVariant?.id)?.qty > 0
                                ?
                                <div className='cart-count-btn'><button
                                    onClick={() => {
                                        if (cart?.isGuest) {
                                            AddToGuestCart(
                                                product,
                                                product?.id,
                                                selectedVariant?.id,
                                                cart?.guestCart?.find((prdct) => prdct?.product_variant_id == product?.variants?.[0]?.id)?.qty - 1,
                                                1,
                                                "remove"
                                            );
                                        } else {
                                            if (cart?.cartProducts?.find((prdct) => prdct?.product_variant_id == selectedVariant?.id).qty == 1) {
                                                removeFromCart(product?.id, selectedVariant?.id)
                                            } else {
                                                addToCart(product.id, selectedVariant.id, cart?.cartProducts?.find(prdct => prdct?.product_variant_id == selectedVariant?.id)?.qty - 1);
                                            }
                                        }
                                    }}
                                ><FaMinus /></button>

                                    <input value={
                                        cart.isGuest === false ?
                                            cart?.cartProducts?.find(prdct => prdct?.product_variant_id == selectedVariant.id)?.qty
                                            : cart?.guestCart?.find(prdct => prdct?.product_variant_id == selectedVariant.id)?.qty
                                    } disabled min='1' type='number' max={selectedVariant?.stock} />

                                    <button onClick={() => {
                                        if (cart?.isGuest) {
                                            const productQuantity = getProductQuantities(cart?.guestCart)
                                            handleValidateAddExistingGuestProduct(
                                                productQuantity,
                                                product,
                                                cart?.guestCart?.find(prdct => prdct?.product_id == product?.id && prdct?.product_variant_id == selectedVariant?.id)?.qty + 1
                                            )
                                        } else {
                                            const quantity = getProductQuantities(cart?.cartProducts)
                                            handleValidateAddExistingProduct(quantity, product)
                                        }
                                    }}><FaPlus /></button>
                                </div>
                                : <button className='product-cart-btn' onClick={() => {
                                    if (cart?.isGuest) {
                                        const quantity = getProductQuantities(cart?.cartProducts)
                                        handleAddNewProductGuest(quantity, product)
                                    } else {
                                        const quantity = getProductQuantities(cart?.cartProducts)
                                        handleValidateAddNewProduct(quantity, product)
                                    }

                                }} ><FaShoppingBasket className='mx-2' size={20} />Add</button>}

                        </div>
                } */}

            </div >

            <QuickViewModal selectedProduct={selectedProduct} setselectedProduct={setselectedProduct} showModal={showModal} setShowModal={setShowModal}
                setP_id={setP_id}
                setP_V_id={setP_V_id}
            />
            <ProductVariantModal showVariantModal={showVariantModal} setShowVariantModal={setShowVariantModal} product={product} />
        </div >
    )
}

export default HeaderProductCard;